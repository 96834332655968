.tab_container {
    width: 88%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6 {
    text-transform: none;
}

.table td{
    font-weight: 500;
   
}



.header{
    font-family: Open Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
}


.downloadDate{
    border: none;
    font-size: 14px;
}

.downloadDate::-webkit-inner-spin-button,
.downloadDate::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.table tr{
    margin-top: 10% !important;
}


.animation{
    animation: slide-top 0.5s 1;
}


.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
    0% {
    
              -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
  }
  @keyframes slide-top {
    0% {
    
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
}
100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
  }
  

.dateInput{
    border: none !important;
    font-size: 12px !important;
    width: 92% !important;
    padding: 0px !important;
    margin: 0px !important;
}

.selectFilter{
    width: 90% !important;
    border: 1px solid #CDC6C3 !important;
    height: 30px;
    font-size: 14px !important;
    margin-top: 8% !important;
    margin-bottom: 5%;
}

.marginBottom{
    margin-bottom: 0px !important;
}

.modal{
    padding: 50px !important;
}

.filterButton{ 
    background-color: black;
    border-radius: 20px;
    color: white;
    width: 90%;
    font-size: 12px;
    margin-right: 10px;
    height: 35px !important;
    border: none;
}

.filterButton1 a{
    color: white !important;
}
.filterButton1{
    background-color: black;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: white !important;
    width: 176px;
    height: 48px !important;
    border: none;
}


.tab_container div {
    width: 25%;
    height: 50px;
}

.AcknowledgedActive {
    background-color: rgba(123, 192, 211, 1);
    color: white;
}

.Acknowledged {
    border-bottom: 10px solid rgba(123, 192, 211, 1);
    color: black;
}

.Accepted {
    border-bottom: 10px solid rgba(226, 149, 37, 1);
    color: black;
}


.AcceptedActive {
    background-color: rgba(226, 149, 37, 1);
    color: white;
}

.Shipped {
    border-bottom: 10px solid rgba(216, 157, 164, 1);
}

.ShippedActive {
    background-color: rgba(216, 157, 164, 1);
    color: white;
}

.Delivered {
    border-bottom: 10px solid rgba(20, 124, 80, 1);
}

.DeliveredActive {
    background-color: rgba(20, 124, 80, 1);
    color: white;
}

.declinebutton {
    width: 90%;
    background-color: white;
    box-sizing: border-box;
    border-radius: 40px;
    border: 1px solid #171717;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    align-items: center;
}

.acceptbutton {
    width: 90%;
    background-color: black;
    box-sizing: border-box;
    border-radius: 40px;
    border: 1px solid #171717;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    align-items: center;
    color: white
}

.instockbutton {
    width: 80%;
    height: 35px !important;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
}
.nullstockbutton {
    width: 80%;
    height: 30px !important;
    background-color: #EBEBEC;
    border-radius: 30px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #808180;
}
.drawer{
    margin:0px;
    overflow:none;
    padding-top: 40px;
}
.drawer div{
    margin:0px;
    }

.drawerheader{
    
    font-family: 'Open Sans';
    font-style: normal;
    
    margin-top:40px;
    margin-bottom:38px !important ;
}
.drawerheader p{
    margin-bottom: 7px;
}
.drawertitle{
    line-height: 24px;
    
}

.shippedButton{
    width: 100%;
    background-color: white;
    border-radius: 30px;
    border: none;
    height: 35px !important;
    color: #147C50;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    border: 1px solid #147C50;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.acceptedButton{
    width: 100%;
    background-color: white;
    border-radius: 30px;
    border: none;
    height: 35px !important;
    color: #E29525;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #E29525;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.drawersubheader{
    padding-left: 33px;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 14px;
    line-height: 16px
}
.acknowledgeButton{
    width: 100%;
    background-color: #7BC0D3;
    border-radius: 30px;
    border: none;
    height: 35px !important;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.solidhr {
    border-top: 3px solid #bbb;
  }
  .instockbuttonDrawer{
    width: auto;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
    padding: 9px 8px 9px 8px;
}
.divider{
    border-bottom: solid 1px #CDC6C3;
    width: 100%;
    margin: 24px 0px 18px 0px
}
.drawerfooter{
    justify-content: flex-end;
    display: flex;
    margin-bottom: 13px;
}
  .drawerbuttoncontainer{
    position: fixed; 
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    right: 0px;
    width: 60%;
    height: 100px;
    background: #EFEFEF;
    align-items: center;
    padding-right: 15px;
} 

.table td{
    font-weight: 500;
   
}

.table tr{
    margin-top: 10% !important;
}



.DrawerButtonDecline{
    /* padding: 16px 63px 16px 65px !important; */
   
    background-color: transparent;
    border-radius: 30px;
    border: 1px solid black;
    color: black;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
 
    padding: 16px 62px 16px 62px !important;
    margin-right: 25px !important;
}
.DrawerButtonAccept{
    /* padding: 16px 63px 16px 65px !important; */
   
    background-color: black;
    border-radius: 30px;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 16px 62px 16px 62px !important;
    margin-right: 25px !important;
}
.active{
    border-bottom: 5px solid #171717;
    font-size: 14px;
}

.active h6{
    font-weight: 700;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.notactive{
    border-bottom: 1px solid #171717;
}

.notactive h6{
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.dropdowntitle{
    color: #C4C4C4 !important;
    text-transform: uppercase;
}

.APInput{
    margin-left: 7%;
    border: none;
    width: 170px;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
}
.APHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
}

.APBody{
    display: flex;
    justify-content: center;
    margin-top: -27px;
    height: 250px;
    flex-direction: column;
    padding: 12px;
}


.APBody1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 250px;
    flex-direction: column;
}

.APMButton{
    width: 150px;
    height: 50px;
    border: 1px solid #000;
    background-color: black;
    color: white;
    border-radius: 30px;
    padding-top: 0px !important;
    margin-top: 5%;
}

.APMButton:hover{
    border: 1px solid rgba(244, 162, 147, 1);
}
.APLabelStatic{
    color: black;
    margin-left: 7%;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Open Sans';
}

.APLabel{
    color: rgba(205, 198, 195, 1);
    font-size: 12px;
    font-family: 'Open Sans';
}

.Ap{

    padding-top: 30px;
    padding-bottom: 30px;
}
.APDown{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 44px;
}
.APlab{
    color: black;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}
.APborder{
    
    border: none;
    border-bottom: 1px solid black;
    width: 50%;

}
.APborder1{
    border: none;
    border-bottom: '2px solid black';
    
}
.noorder{
    padding:  100px;
    color:#C4C4C4;
}
