.tab_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6 {
    text-transform: none;
}



.tab_container div { 
    width: 25%;
    height: 50px;
}


.solidhr {
    border-top: 3px solid #bbb;
  }
  .active{
    border-bottom: 5px solid #171717;
}
.notactive{
    border-bottom: 1px solid #171717;
}
.cardtitle h1{
    font-size: 24px ;
    font-weight: 700;
  
}
.cardtitle a{
    text-decoration: underline;
    text-transform: capitalize;
    color: black;
}
.cardtitle{
    padding: 0px 70px 0px 50px
}
.descriptionbox{
    
    padding: 7px 7px 43px 7px;
    border: 1px solid #CDC6C3;
    border-radius: 6px;

}
.acclogo img{
    width: 100% !important;
}
.descriptionboxtext{
    font-weight: 700;

    line-height: 16px;
    color: #CDC6C3;
    text-transform: capitalize;

}
.APHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
}

.APBody{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    flex-direction: column;
}
.APCLabel{
    width: 106%;
    font-weight: 700;
}

.APMButton{
    width: 100%;
    height: 50px;
    border: 1px solid #000;
    background-color: white;
    color: black;
    border-radius: 30px;
    padding-top: 0px !important;
    margin-top: 5%;
}

.APMButton:hover{
    border: 1px solid rgba(244, 162, 147, 1);
}

.table_tbody{
    width: 100%;
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 10px;
}

.table_tbody tr{
    display: block;
    text-align: center; 
    /* border-bottom: 0.2px solid rgba(0,0,0, 0.2) !important; */
    border: solid 1px #CDC6C3 !important;
    border-style: solid !important;
    margin-top:  10px;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}


.verifyingButton {
    width: 80%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #fff;
}

.BSButton {
    width: 80%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 60%;
    color: #fff;
}




.blackButton {
    background-color: #000000;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    height: 50px;
    line-height: 16px;
    padding-top: 0px !important;
    width: 100%;
    border: 1px solid;
    text-align: center;
    color: #fff;
}

.APLabel{
    /* color: rgba(205, 198, 195, 1);
    font-size: 0.9vw;
    font-family: 'Open Sans';
    font-weight: bold; */
    width:'113px';
    font-weight:"700";
}


.DPButton {
    width: 80%;
    background-color: rgba(123, 192, 211, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 60%;
    color: #fff;
}

.publishButton {
    width: 80%;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
}

.APInput{
    /* margin-left: 7%; */
    border: none;
    width: 100%;
    background-color: white;
    color: black !important;
    border: 1px solid rgba(205, 198, 195, 1);
    

}

.APTextArea{
    border: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    border-radius: 10px;
    height: 80px;
    margin-top: 2%;
}
.divider{
    border-bottom: 1px solid #CDC6C3;
    margin: 15px 0px 32px 0px;
    width: 100%;
}.filterButton1{
    background-color: black;
    text-align: center;
    border-radius: 20px;
    color: white;
    align-items: center;
    width: 180px;
    height: 45px !important;
    border: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding:0;
  
}