
.carouselImg{
    width: 100px !important;
    height: 100px !important;
    padding-left: 30px !important;
}


.middle h5{
    font-family: 'Poppins' !important;
}

.tab_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6{
    text-transform: none;
}

.tab_container div {
    width: 20%;
    height: 50px;
}

.fontSize12{
    font-size: 12px;
}
.animation{
animation: slide-top 0.5s 1;
}


.divider{
    border-bottom: 1px solid #CDC6C3;
    margin-top: 12.5px;
    margin-bottom: 12.5px;
}
/* .dropdown-menu.show{
    
} */
.tabOrders{
    border-bottom: 1px solid #171717;
}
.tabOrdersActive{
    border-bottom: 4px solid #171717;
}



.table{
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 10px;
}

.table tr{
    text-align: center; 
    background-color: #CDC6C3; 
    border: solid 1px #CDC6C3;
    border-style: solid none;
    padding: 10px;
    background-color: #CDC6C3;
}
.table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
    border-color: transparent;
}

.table td:last-child{
    border-right-style: solid;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
    border-color: transparent;
}

.APHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    margin-bottom: 10px;
}

.APBody{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    flex-direction: column;
}


.APBody1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 250px;
    flex-direction: column;
}

.APMButton{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    width: 150px;
    height: 50px;
    border: 1px solid #000;
    background-color: white;
    color: black;
    border-radius: 30px;
    padding: 20px !important;
    margin-top: 5%;
}

.APMButton:hover{
    border: 2px solid black;
}

.table_tbody{
    width: 100%;
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 10px;
}

.table_tbody tr{
    display: block;
    text-align: center; 
    /* border-bottom: 0.2px solid rgba(0,0,0, 0.2) !important; */
    border: solid 1px #CDC6C3 !important;
    border-style: solid !important;
    margin-top:  10px;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}


.verifyingButton {
    width: 80%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #fff;
}



.publishButton {
    width: 80%;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    color: #fff;
}

.BSButton {
    width: 80%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 60%;
    color: #fff;
}


.carouselImg{
    width: 100% !important;
}

.filterButton{
    background-color: black;
    border-radius: 20px;
    color: white;
    width: 100%;
    font-size: 12px;
    margin-right: 10px;
    height: 35px !important;
    border: none;
}
.DownloadCatalouge{
    color: white;
}
.DownloadCatalouge > a:hover{
    color: white !important;
}

.filterButton1{
    background-color: black;
    text-align: center;
    border-radius: 20px;
    color: white;
    align-items: center;
    width: 180px;
    height: 45px !important;
    border: none;
    display: flex;
    justify-content: center; 
    margin: 0px 9px;
    padding:0;
  
}

.editIcon{
    position: absolute;
    top: 0;
}

.filterButton1 img{
    padding-top: 10px;
}



.blackButton {
    background-color: #000000;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 17px ;
    padding-top: 14px !important;
    width: 150px;
    border: 1px solid;
    text-align: center;
    color: #fff;
}

.blackYesButton {
    background-color: #000000;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 30px;
    width: auto;
    border: 1px solid;
    text-align: center;
    color: #fff;
}



.whiteNoButton {
    background-color: #fff;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 30px;
    width: auto;
    margin-left: 10px;
    border: 1px solid #C2B395;
    text-align: center;
    color: #000;
}

.APLabel{
    color: rgba(205, 198, 195, 1);
    font-size: 12px;
    font-family: 'Open Sans';
}


.DPButton {
    width: 80%;
    background-color: rgba(123, 192, 211, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 60%;
    color: #fff;
}

.publishButton {
    width: 80%;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
}

.marginBottom{
    font-size: 12px !important;
}

.APInput{
    margin-left: 7%;
    border: none;
    width: 170px;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    text-align: center;
}

.APInputWithoutBorder{
    margin-left: 7%;
    border: none;
    width: 170px;
    font-size: 12px;
    text-align: center;
}

.APSelect{
    border: none;
    width: 100%;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center !important;
    text-indent: 1px;
    text-align-last:center !important;
    text-overflow: '';
}

.APSelect option{
    text-align: center !important;
}
.APSelect:focus{
    outline: none !important;
    box-shadow: none !important;
}

.APSelect::-ms-expand {
    display: none !important;
}

.APLabelStatic{
    color: black;
    font-size: 12px;
    padding-left: 0px !important;
    font-weight: 700;
    text-align: center;
    font-family: 'Open Sans';
    padding-left: 24px;
}

.APTextArea{
    border: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    border-radius: 10px;
    height: 13vh;
    padding: 10px;
    margin-top: 2%;
}
.next{
    display: flex;
    justify-content: center;
}
.middle{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden ;
    padding-bottom: 0px;
}
.dzu-dropzone{
    overflow: hidden ;
    width: 100px;
    height: 18vh;
    min-height: 220px;
}
.APBody2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 238px;
    flex-direction: column;
  margin-bottom: -9px;
    width: 252px;
}

.APHeader2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.blackButton1 {
    background-color: #fff;
   width: 296px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-top: 0px !important;
   text-align: center;
    border: 2px solid papayawhip; 
    color: black;
}

.h2{
    font-size: 1.5rem;
}
.APHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6%;
}
.APBody{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 116px;
    flex-direction: column;
}

.APMButton1{
    width: 150px;
    height: 50px;
    border: 1px solid #000;
    background-color: black;
    color: white;
    border-radius: 30px;
    padding: 10px 34px 34px 34px !important;
    margin-top: 8%;
}
.AP1{
    margin-top: 22px;
    margin-bottom: 24px;
}
.APMButton:hover{
    border: 2px solid black;
}
.APborder{
    max-width:59%;
    border: none;
    border-bottom: 1px solid black;
}
.APlab{
    width: 284px !important;
}
.APborder1{
    border: none;
    border-bottom: '2px solid black';
    
}
.APDown{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 20px;
}
.AP2{
        margin-top: 22px;
        margin-bottom: 24px;
}