:root {
    --white: #fff;
    --orange: #ffffff;
  }
  
  .outer-container1 {
    max-width: 100%;
    margin: auto;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
  }
/*   
  .outer-container1 > div {
    flex: 1;
    padding: 20px;
  } */
  
  .outer-container1 .carousel1-wrapper {
    background: var(--orange);
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  
  .outer-container1 .carousel-wrapper1 p,
  .carousel-item1 {
    font-weight: 700;
    font-size: 20px;
    color: "#000";
    display: block;
    line-height: 1.3;
  }
  
  .leading-text {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 6px;
  }
  
  .MuiFormControl-root{
    width: 100% !important;
  }


  .unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

  .dropdown-menu .show{
    left: 10% !important;
  }

  .carousel1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
  }
  
  .carousel-button1 {
    display: flex;
    align-self: center;
    padding-top: 20px !important;
    padding: 10px;
    max-width: 50px;
    border-radius: 30px;
    background: var(--orange);
    border: 2px solid var(--white);
  }
  
  .carousel-button1 svg {
    height: 26px;
    width: 26px;
  }
  .carousel-button1 path {
    fill: var(--white);
  }

  .dzu-dropzone{
    border: none !important;
  }
  
  .slides1 {
    align-self: flex-start;
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-inner1 {
    position: relative;
    /* height: 30rem; */
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .main-panel .navbar{
    box-shadow: 4px 8px 20px 0px #908C8533 !important;
    border-radius: 0px !important;
    background-color: white;

  }


  
  .carousel-inner1::before,
  .carousel-inner1::after {
    content: "";
    z-index: 1;
    position: absolute;
    width: 100%;
    pointer-events: none;
  }
  
  .carousel-inner1::before {
    top: 0;
    background: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0) 0%,
      rgb(255, 255, 255) 100%
    );
  }
  
  .carousel-inner1::after {
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgb(252, 251, 250) 0%,
      hsla(0, 0%, 100%, 0) 100%
    );
  }
  p{
      margin-bottom: 0px !important;
  }
  
  .carousel-item1 {
    position: absolute;
    background: none;
    border: none;
    width: 100% !important;
    
    padding: 0;
    margin: 0;
    opacity: 0;
    top: 112px;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }
  
  .carousel-item1.visible {
    opacity: 1;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    border-top: none !important;
    font-weight: 100 !important;
    font-size: 14px;
  }
  
  .carousel-item1.active {
    opacity: 1;
    font-weight: 600 !important;
    border: none;
    font-size: 20px;
    border-bottom: 2px solid rgba(0,0,0,0.8) !important;
    border-top: 2px solid rgba(0,0,0,0.8) !important;
    /* border-bottom: 2px solid rgba(0,0,0,0.8); */
  }
  