.tab_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6 {
    text-transform: none;
}


.blackYesButton {
    background-color: #000000;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 30px;
    width: auto;
    border: 1px solid;
    text-align: center;
    color: #fff;
}



.whiteNoButton {
    background-color: #fff;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 30px;
    width: auto;
    margin-left: 10px;
    border: 1px solid #C2B395;
    text-align: center;
    color: #000;
}


.tab_container div {
    width: 25%;
    height: 50px;
}

.errorText{
    color: red;
    font-size: 0.8rem;
    line-height: 15px;
}

.CPButton{
    width: 150px !important;
    padding-top: 10px !important;
    border: none;
}

.gobackButton{
    border: 1px solid #CDC6C3;
    border-radius: 30px;
    padding: 10px 30px;
    background-color: #fff;
}

.changesButton{
    border: none;
    margin-left: 15px;
    border-radius: 30px;
    padding: 10px 30px;
    color: #fff;
    background-color: #000;
}

.profileEditIcon{
    position: absolute;
    top: 10px;
    right: 10px;
}

.solidhr {
    border-top: 3px solid #bbb;
}
.active{
    border-bottom: 5px solid #171717;
}
.notactive{
    border-bottom: 1px solid #171717;
}
.cardtitle h1{
    font-size: 24px ;
    font-weight: 700;

}
.cardtitle a{
    text-decoration: underline;
    text-transform: capitalize;
    color: black;
    cursor: pointer;
}
.cardtitle{
    padding: 0px 70px 0px 50px
}
.descriptionbox{
    width: 108%;
    padding: 7px 7px 43px 7px;
    border: 1px solid #CDC6C3;
    border-radius: 6px;
    font-family: Open Sans;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;


}
.acclogo img{
    width: 100% !important;
}
.descriptionboxtext{
    font-weight: 500; 

    line-height: 16px;
    color: #CDC6C3;
    text-transform: capitalize;
    font-family: Open Sans;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 16px;
letter-spacing: 0em;
text-align: left;


}
.APHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
}

.APBody{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    flex-direction: column;
}

.APMButton{
    width: 150px;
    height: 50px;
    border: 1px solid #000;
    background-color: white;
    color: black;
    border-radius: 30px;
    padding-top: 0px !important;
    margin-top: 5%;
}

.APMButton:hover{
    border: 1px solid rgba(244, 162, 147, 1);
}

.table_tbody{
    width: 100%;
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 10px;
}

.table_tbody tr{
    display: block;
    text-align: center; 
    /* border-bottom: 0.2px solid rgba(0,0,0, 0.2) !important; */
    border: solid 1px #CDC6C3 !important;
    border-style: solid !important;
    margin-top:  10px;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}


.verifyingButton {
    width: 80%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #fff;
}

.BSButton {
    width: 80%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 60%;
    color: #fff;
}


.blackButton {
    background-color: #000000;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-top: 0px !important;
    width: 150px;
    border: 1px solid;
    text-align: center;
    color: #fff;
}

.APLabel{
    color: rgba(205, 198, 195, 1);
    font-size: 0.9vw;
    font-family: 'Open Sans';
    font-weight: bold;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

}


.DPButton {
    width: 80%;
    background-color: rgba(123, 192, 211, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 60%;
    color: #fff;
}

.publishButton {
    width: 80%;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
}
.activeinput{
    border: none;
    width: 100%;
    background-color: white;
    color: rgba(205, 198, 195, 1) ;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
}
.APInput{
    /* margin-left: 7%; */
    border: none;
    width: 100%;
    background-color: white;
    color: black ;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
font-family: Open Sans;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;


}

.APTextArea{
    border: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    border-radius: 10px;
    height: 80px;
    margin-top: 2%;
}
.divider{
    border-bottom: 1px solid #CDC6C3;
    margin: 15px 0px 32px 0px;
    width: 100%;
}

.CPButton{
    text-transform: uppercase;
    font-weight: bold;
    background-color: black;
    color: white;
    border-radius: 30px;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px 0px 16px 0px;
    margin-bottom: 16px;
}
.CPButtonGoBack{
    background-color: white;
    color: black;
    border-radius:30px ;
}
.CPInput{
    border: 1px solid #CDC6C3;
    box-sizing: border-box;
    border-radius: 6px;

}

