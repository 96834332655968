.tab_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6 {
    text-transform: none;
}

.marginBottom{
    margin-bottom: 0px !important;
}



.drawerEditInput{
    border: none;
    border-bottom: 1px solid #000;
    font-size: 1rem;
}

.filterButton{
    background-color: black;
    border-radius: 20px;
    color: white;
    width: 100%;
    height: 35px !important;
    border: none;
}

.tab_container div {
    width: 25%;
    height: 50px;
}
.linktext{
    color: #1B74B9;
}
.AcknowledgedActive {
    background-color: rgba(123, 192, 211, 1);
    color: white;
}

.Acknowledged {
    border-bottom: 10px solid rgba(123, 192, 211, 1);
    color: black;
}

.Accepted {
    border-bottom: 10px solid rgba(226, 149, 37, 1);
    color: black;
}


.AcceptedActive {
    background-color: rgba(226, 149, 37, 1);
    color: white;
}

.Shipped {
    border-bottom: 10px solid rgba(216, 157, 164, 1);
}

.ShippedActive {
    background-color: rgba(216, 157, 164, 1);
    color: white;
}

.Delivered {
    border-bottom: 10px solid rgba(20, 124, 80, 1);
}

.DeliveredActive {
    background-color: rgba(20, 124, 80, 1);
    color: white;
}

.declinebutton {
    width: 90%;
    background-color: white;
    box-sizing: border-box;
    border-radius: 40px;
    border: 1px solid #171717;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    align-items: center;
}

.acceptbutton {
    width: 90%;
    background-color: black;
    box-sizing: border-box;
    border-radius: 40px;
    border: 1px solid #171717;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    align-items: center;
    color: white
}

.instockbutton {
    width: 140px !important;
    height: 35px !important;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    padding: 2px 20px 0px 20px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
}
.nullstockbutton {
    width: 140px !important;
    height: 35px !important;
    background-color: #EBEBEC;
    border-radius: 30px;
    font-family: 'Open Sans';
    padding: 2px 20px 0px 20px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #808180;
}
.drawer{
    margin:0px;
    overflow:none;
    padding-top: 40px;
}
.drawer div{
    margin:0px;
    }

.drawerheader{
    
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 1.3rem;
    margin-top:40px;
    padding-left: 33px !important;
    margin-bottom:38px !important ;
}


.drawerTitle{
    margin-bottom: 7px;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 24px;
    letter-spacing: 0em;
    text-transform: uppercase;
    text-align: left;
}

.drawersubtitle{
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.productSpan{
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.productHeader{
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.drawersubtitletext{
    font-family: Open Sans;
    font-size: 14px;
    padding-top: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.drawerSpan{
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B74B9 !important;
    text-decoration: underline  !important;
}

.drawerSpanOrderID{
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}


.linkSpan{
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B74B9 !important;
    text-decoration: underline  !important;
}


.drawerSpan:hover{
    color: #1B74B9 !important;
}

.drawerheader p{
  
}
.drawertitle{
    line-height: 24px;
    
}
.drawersubheader{
    padding-left: 33px;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 14px;
    line-height: 16px
}
.acknowledgeButton{
    width: 140px !important;
    height: 35px !important;
    margin-top: 5px;
    background-color: #7BC0D3;
    border-radius: 30px;
    border: none;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}




.shippedButton{
    width: 130px;
    background-color: white;
    border-radius: 30px;
    border: none;
    height: 35px !important;
    color: #147C50;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #147C50;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.acceptedButton{
    width: 130px;
    background-color: white;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: #E29525;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #E29525;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.deliveredButton{
    width: 130px;
    background-color: #147C50;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #147C50;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.returnedButton{
    width: 130px;
    background-color: white;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: #963240;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #963240;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}
.cancelledButton{
    width: 130px;
    background-color: #963240;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #963240;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}


.solidhr {
    border-top: 3px solid #bbb;
  }
  .instockbuttonDrawer{
    width: auto;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
    padding: 9px 8px 9px 8px;
}
.divider{
    border-bottom: solid 1px #CDC6C3;
    width: 110%;
    margin: 24px -50px 18px -50px
}
.drawerfooter{
    justify-content: flex-end;
    display: flex;
    margin-bottom: 13px;
}
  .drawerbuttoncontainer{
    position: fixed; 
    bottom: 0;
    
    /* display: flex; */
    /* justify-content: flex-end; */
    right: 0px;
    width: 40%;
    height: 60px;
    background: #EFEFEF;
} 

.table td{
    font-weight: 500;
    margin-top: 50px !important;
   
}



.tableContenttd{
    text-align: center;
    padding-top: 24px !important;
    padding-bottom: 27px;
}


.declinebutton {
    width: 176px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 40px;
    border: 1px solid #171717;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    height: 48px !important;
    line-height: 16px;
    /* identical to box height, or 114% */
    align-items: center;
}

.acceptbutton {
    width: 176px !important;
    background-color: black;
    box-sizing: border-box;
    border-radius: 40px;
    border: 1px solid #171717;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    height: 48px !important;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    align-items: center;
    color: white
}



.DrawerButtonDecline{
    /* padding: 16px 63px 16px 65px !important; */
   
    background-color: transparent;
    border-radius: 30px;
    border: 1px solid black;
    color: black;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    width: 176px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px !important;
}
.DrawerButtonAccept{
    /* padding: 16px 63px 16px 65px !important; */
   
    background-color: black;
    border-radius: 30px;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    width: 176px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px !important;
}
.active{
    border-bottom: 5px solid #171717;
}
.notactive{
    border-bottom: 1px solid #171717;
}
.dropdowntitle{
    color: #C4C4C4 !important;
    text-transform: uppercase;
}
.details{
    font-weight: 400;
    font-size: 14px;
    padding-left: 27px;
    margin-bottom: 100px;
   
}
.odrawerdetailssection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}