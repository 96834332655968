.tab_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6{
    text-transform: none;
}

.tab_container div {
    width: 20%;
    height: 50px;
}

.fontSize12{
    font-size: 12px;
}

.font-weight-700{
    padding-top: 10%;
}

.APHeader{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -20px;
    transform: translateY(50%);
   
    
}

.APBody{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 238px;
    flex-direction: column;
    border: 1px solid darkgray;
    width: 252px;
}


.APBody1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 250px;
    flex-direction: column;
}

.APMButton{
    width: 150px;
    height: 50px;
    border: 1px solid #000;
    background-color: white;
    color: rgb(250, 216, 216);
    border-radius: 30px;
    padding-top: 0px !important;
    margin-top: 5%;
}

.APMButton:hover{
    border: 1px solid rgba(244, 162, 147, 1);
}


.BSButton {
    width: 80%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 60%;
    color: #fff;
}


.blackButton {
    background-color: #000;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-top: 0px !important;
    width: 150px;
    border: 1px solid;
    text-align: center;
    color: #fff;
}

.APLabel{
    color: rgba(205, 198, 195, 1);
    font-size: 12px;
    font-family: 'Open Sans';
}


.DPButton {
    width: 80%;
    background-color: rgba(123, 192, 211, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 60%;
    color: #fff;
}

.publishButton {
    width: 80%;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
}

.APInput{
    margin-left: 7%;
    border: none;
    width: 170px;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
}

.APLabelStatic{
    color: black;
    margin-left: 7%;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Open Sans';
}

.APTextArea{
    border: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    border-radius: 10px;
    height: 80px;
    margin-top: 2%;
}
.next{
    display: flex;
    justify-content: center;
}
.middle{
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding-bottom: 0px;
}