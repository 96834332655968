@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
.container{
    background-image: url('https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Pattern.png');
    background-size: 100%;
    width: 100%;
    height: 100vh;
    font-family: 'Open Sans';

}

.input{
    width: 100%;
    background-color: #fff;
    border: 1px solid #CDC6C3;
    border-radius: 6px;
    height: 50px;
}

.button{
    border-radius: 30px;
    border: none;
    background-color: black;
    width: 100%;
    height: 45px;
    padding-top: 0% !important;
    margin-top: 5vh;
    color: white;
}

.button1{
    border-radius: 30px;
    border: none;
    background-color: black;
    width: 100%;
    height: 45px;
    padding-top: 0% !important;
    font-weight: 700;
    margin-top: 5vh;
    color: white;
}

.button2{
    border-radius: 30px;
    border-style: solid;
    border: 2px solid #000;
    background-color: white;
    width: 100%;
    font-weight: 700;
    padding-top: 0% !important;
    height: 45px;
    margin-top: 2vh;
    color: #000;
}


.passswordFields{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header{
    font-size: 53px;
    font-weight: 600;
}