.panel{
    /* background-color: white; */
    margin-top: 60px;
    
}
.notifcation{
    margin-top: 32px;
}
.divider{
    width: 100%;
    border-bottom: 1px solid #CDC6C3;
    margin-top: 16px;
}
.notifcationtitle{
    margin: 0px 0px 12px 0px;
}
.notifcationtitle b{
    font-weight: 700;
    font-size: 18px;
    line-height: 24.51px;
}
.notifcationcontent a{
    text-decoration: underline;
    color: #1B74B9;

}

.notifcationtimestamp{
    color:  #CDC6C3;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;

}