@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

.font-weight-900{
    font-weight: 900;
}

.font-weight-800{
    font-weight: 800;
}
.MuiRadio-colorSecondary.Mui-checked{
    color:#147C50 !important;
}
.ProductCatalogue_filterButton1__3bpqx > a:hover{
    color: white;
}
.font-weight-700{
    font-weight: 700;
}
#product-catalogue{
    padding: 0 !important;
}
.show.dropdown.nav-item > .dropdown-menu.show{
    transform: translate(-26px, 65px) !important;
    width: 115%;
}
.MuiFormControlLabel-root{
    margin-bottom:-5px ;
}



.numberCard{
    padding-left: 24px;
    padding-top: 10px;
}

.example-custom-input p{
    width: 110px !important;
}

.numberCard h5{
    font-size: 14px;
    margin-top: 10px;
    color: rgba(23, 23, 23, 1);
}

.MuiBackdrop-root{
    background-color: rgba(0,0,0, 0.3) !important;
    backdrop-filter: blur(0.7px) !important;
}

.makeStyles-modal-15{
    z-index: 40000 !important;
}

.makeStyles-modal-16{
    z-index: 40000 !important;
}

.makeStyles-modal-32{
    z-index: 40000 !important;
}

.MuiDrawer-paper{
    box-shadow: none !important;
}

.alert-warning{
    background-color: rgba(226, 149, 37, 0.16) !important;
}

.dzu-inputLabel{
    right: 0px;
} 
table {
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    border-radius:10px
}
.navbar .navbar-nav .nav-item .nav-link{
    background-color: white;
    padding-top:16px;
}
.card .card-category, .card label{
    background-color: transparent;
}
.MuiDrawer-paper{
    width: 60%;
}
.dflexC{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dflexR{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.dflexRJB{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.content{
    padding-left: 16px !important;
    margin-left: 0px !important;
    padding-right: 32px !important;
    margin-right: 0px !important;
}

.font-weight-600{
    font-weight: 600;
}

.font-weight-500{
    font-weight: 500 !important;
}

.font-weight-400{
    font-weight: 400;
}

.font-weight-300{
    font-weight: 300;
}


.textAlign-left{
    text-align: left;
}

.textAlign-right{
    text-align: right;
}

.textAlign-center{
    text-align: center;
}

.green{
    color: #109D62;
}

.height-100{
    height: 100% !important;
}


.width-100{
    width: 100%;
}


.width-50{
    width: 50%;
}

h1{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.p-20{
    padding: 20px !important;
}

.width-30{
    width: 30%;
}
.main-panel .navbar{
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.outer-container{
    box-shadow: none !important;
    border: none !important;
}
.carousel-item.visible{
    border-bottom: 1px solid rgba(0,0,0,0.3) !important;
    border-top: 1px solid rgba(0,0,0,0.3) !important;

}
.carousel-item.active{
    font-weight: 700 !important;
    border-top:2px solid rgba(0,0,0,0.8) !important;
}
.table-row-divider{
    border-bottom: 1px solid rgba(0,0,0,0.3);
    width: 80%;

}
table { border-collapse: collapse; }
td { border-bottom: none !important;  }
tr:first-child td:first-child { border-top-left-radius: 10px; }
tr:first-child td:last-child { border-top-right-radius: 10px; }
tr:last-child td:first-child { border-bottom-left-radius: 10px; }
tr:last-child td:last-child { border-bottom-right-radius: 10px; }

.card-blue{
    margin: 0px 56px 0px 0px;
    width: 100%;
    height: 80%;
    background-color: rgba(123, 192, 211, 0.3);
    border-radius: 8px !important;
}

.card-pink{
    background-color: rgba(150, 50, 64, 0.3);
    width: 100%;
    height: 80%;
    border-radius: 8px !important;
}


.card-white{
    background-color: white !important;
    height: 100%;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
}
.sidebar{
    position: fixed;    
}
.wrapper{
    height: 100% !important;
}
.sidebar, .main-panel{
    overflow: hidden !important;
    height: auto;
}
.fixed-plugin{
    display: none;
}
.canvasjs-chart-toolbar{
    display: none;
}

.carousel-item.active{
    border-bottom: 2px solid rgba(0,0,0,0.8) !important;
    font-weight: bold;
}
.sidebar-logo{
    position: absolute;
    bottom: 0;
    padding: 0 0 50px 55px;
    color: black;
    font-size: 12px;
}
.MuiListItem-button:hover{
    text-decoration: none !important;
    background-color: transparent !important;
}
.MuiFormLabel-root.Mui-focused{
    color: #C4C4C4;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.custom-file-input {
    color: transparent;
    opacity: 1;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Upload Cover image';
    color: black;
    display: inline-block;
    text-decoration: underline;
    border-radius: 3px;
    padding: 5px 3px;
    outline: none;
    white-space: nowrap;
   
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
  }
 
 
.Ap{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.APDown{
    display: flex !important;
    align-items: center !important;
    align-content: center !important;
}
.APlab{
    color: black !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 13px !important;
   
}
.inwidth{
    width: 50% !important;
}
.badge-warning{
    color: rgb(226, 149, 37);
    background-color: rgb(244, 228, 206);
    margin-right: 30px;
    font-weight: 800;
    padding-top: 10px;  
    padding-bottom: 10px;   

} 
.fzCwXm:hover:enabled, .fzCwXm:focus:enabled{
    color: black !important;
    background-color: rgb(235, 233, 230) !important;
}
.fzCwXm{
    box-shadow: none !important;
    background-color: rgb(235, 233, 230) !important;
}

.ehkxHv{
    background: none !important;
    box-shadow: none !important;
    color: black !important;
    cursor:default !important;
    font-size: 40px !important;

}
.kMthTr{
    display: none !important;
}
.fzDWsc{
    background-color: #ebe9e6 !important;
    color: black !important;
    box-shadow: none !important;
}
.ebe9e6{
    background-color: #ebe9e6 !important;
    color: black !important;
    box-shadow: none !important;
}
.xzcEF {
display: none !important;
}
.cGKbZh{
    background: none !important;
    box-shadow: none !important;
    color: black !important;
    cursor:default !important;
    font-size: 40px !important;
}
.dzu-dropzone{
    overflow: hidden !important;
   
    
}
.alice-carousel__stage-item {
    margin-top:-10px;
}
.alice-carousel__dots{
    position: absolute;
}
 
  /* .dzu-inputLabel{
     display: block !important; 
   position: relative !important;
} */

/* .dzu-dropzone{
    display: block !important;
    height: 10vh !important;
    min-height:140px !important
} 
    */


.rs-uploader-trigger input{
    display: none !important;
}


.loader,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
}
.loader {
  font-size: 10px;
  margin-left: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.mui-modal{
    z-index: 50000 !important;
   
}


div{
    scrollbar-width: thin;
}

div::-webkit-scrollbar {
    width: 8px;
}



div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}



div::-webkit-scrollbar-track {
    background-color:#E3E3E3;
    border-radius: 10px;
    box-shadow: none !important;
    padding-right: 10px;
}


div::-webkit-scrollbar-thumb {
    background-color: #CDC6C3;
    border-radius: 10px;
    box-shadow: none !important;
    background-clip: padding-box;
}


#canvasjs-react-chart-container-1{
    height: 338px !important;
    border: 1px solid grey !important;
    border-radius: 10px !important;
    border-top-left-radius: 20px;
    padding: 10px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
  
}
.canvasjs-chart-canvas{
    padding-right: 20px;
    height: 320px !important;
    
}

select{
    background-color: white !important;
}





.csv-input{
    width: 92%;
    height: 250px;
    color: transparent;
}

.csv-input{

}

.csv-input::-webkit-file-upload-button {
    display: none;
 }

 .password-container{
     position: relative;
     border: 1px solid #CDC6C3 !important;
     border-radius: 5px;
     height: 50px;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: row;
 }

 .password-container input{
     border: none;
     border-radius: none;
     height: 45px !important;
 }

 .password-container img{
     position: absolute;
     right: 20px;
 }

 .password-container i{
    position: absolute;
    right: 20px;
}