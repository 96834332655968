.cardstyle{
    border-radius: 8px;
    background-color: #B7DAE4;
    background-image: url('../../assets/img/alertbg.png') ;
    background-repeat: repeat; 
}



.slideBbottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(70px);
              transform: translateY(70px);
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(70px);
              transform: translateY(70px);
    }
  }
  