@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');
body{
    font-family: 'Open Sans', sans-serif;
}
.sidebar{
    background-color: white !important;
}
.sidebar:after, body > .navbar-collapse:after{
    background-color:white !important;
    background-image: none !important;
    color: black !important;
}
.sidebar .nav li .nav-link, body > .navbar-collapse .nav li .nav-link{
    color:black;
    margin: 0px !important;
    padding-left: 40px;
}
.sidebar .nav li.active .nav-link, body > .navbar-collapse .nav li.active .nav-link{
    color: white;
    background-color: #CDC6C3;
    margin: 0px !important;
    padding-left: 40px;

}
.Navbar-icon{
    margin-right: 24px;
}

.dzu-inputLabel{
    width: 95%;
    height: 100%;
}

.dzu-input{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: none !important;
}

.dzu-input::-webkit-file-upload-button {
    display: none;
 }


.makeStyles-paper-16{
    height: auto !important;
}

.ProductCatalogue_DownloadCatalouge__1HcHT:hover{
    color: white !important;
}


.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.react-select__control{
    width: 100% !important;
}

.slide{
    min-width: 100% !important;
    margin-left: 100px;
}

.slider-wrapper li{
    min-width: 100% !important;
}

.carousel .slider-wrapper {
    height: 100% !important;
}

.carousel.carousel-slider{
    height: 100% !important;
}

.carousel .slider-wrapper.axis-horizontal .slider{
    height: 100% !important;
    min-width: 100% !important;
}

.slide{
    width: 100% !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide{
    min-width: 100% !important;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-6-7 19:12:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }


  .react-select__value-container {
    overflow-y: auto !important;
    max-height: 80px;
  }
  
  .react-select__control {
    width: 410px;
    
  }
  


  .makeStyles-paper-87{
    width: 300px !important;
  }

  .swal-button{
      background-color: black !important;
      color: white !important;
  }

  input{
      font-family: 'Open Sans';
  }

  .nav-link{
      text-transform: capitalize !important;
  }


  .content{
      margin-top: 0.5rem;
      margin-left: 0.3rem;
  }

.item{
    background-color: black;
    height: 200px;
}


.react-select__control::-webkit-scrollbar {
    display: none;
  }

.dropdown-menu.show{
    left: 13% !important;
    width: 90%;
    padding: 20px;
    border: none;
    box-shadow: 4px 8px 10px rgba(99, 94, 88, 0.2);
}

.MuiTypography-body1{
    color: #888888;
}

.dropdown-toggle::after {
    display: none !important;
}


.nav-link.disabled{
    color:#171717;
    font: OpenSans;
    font-weight: 700;
    font-size: 12px;
}
.card .card-category, .card label{
    background-color: #CDC6C3;
    font: OpenSans;
    font-weight: 700;
    font-size: 12px;
    border-radius: 8px;
    /*width: 1064px;
    margin-right: 60px;*/
}
/* .nav-item a{
    margin-right: 60px;
} */
.navbar{
    background-color: #CDC6C3;
    padding-left: 25px;
    width: 100%;
    border-radius: 8px;
    
}
.navbar th{
    font: OpenSans;
    font-weight: 700;
    font-size: 12px;
    /*padding-right: 60px;*/
}
td{
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    /*padding-right: 60px;*/
}
.sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p{
    text-transform: capitalize;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
}
.card .card-title{
   
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    /*margin-bottom:16px;*/
}

.order tr{
    margin-top: 10px;
}
.order td{
    background-color: white;
    /* border-radius: 8px; */
    
    align-items: center;

    margin-bottom: 6px;
    padding-top: 15px;
    margin-top: 10px !important;
    padding-bottom: 25px;
    align-content: center;
    
}
body{
    background-color: #F1EEEA;
}

.makeStyles-paper3-54{
    height: auto !important;
}

.alice-carousel__dots{
    margin-top: 0px !important;
    bottom: -20px !important;
    width: 100%;
    padding-left: 0px !important;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: black !important;
}

.alice-carousel__dots-item:not(.__custom){
    background-color: rgba(0,0,0,0.2) !important;
}

.alice-carousel__prev-btn{
    display: none !important;
}

.alice-carousel__next-btn{
    display: none !important;
}

.card .card-header{
    background-color: #E5E5E5;
    /*margin-bottom: 16px;*/
    padding:0px;
}

.main-panel .navbar {
    padding:0px
}
.card{
    background-color: #e5e5e5;
    border:none;
}
.main-panel .navbar{
    margin:0px;
}
tbody{
    width: 100%;
    display: block;
}
.navbar tr{
    width: 100%;
    display:flex;
    padding-left: 15px;
}

button{
   
    height: 45px ;

}

.order tr{
    width: 100%;
    display:flex;
    padding-left: 15px;
} 
.order button{
    background-color:#147C50;
    border-radius: 15px;
    border-color: #147C50;
    color: white;
    height:32px;
    align-items: center;
    display:flex;
    
}
.order button:hover{
    background-color:#147C50;
    border-radius: 15px;
    border-color: #147C50;
    color: white;
    
}
.btn-light{
    border: 1px solid #171717;
    box-sizing: border-box;
    border-radius: 40px !important;
    background-color: white  !important;
    color: black !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    border-color: black !important;
    padding-top: 16px;
    padding-bottom: 16px;
    

}
.btn-dark{
    border: 1px solid #171717;
    box-sizing: border-box;
    border-radius: 40px !important;
    background-color: black  !important;
    color: white !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    border-color: black !important;
    padding-top: 16px;
    padding-bottom: 16px;
    
}
.canvasjs-chart-toolbar button{
    display: none;
}

.dzu-submitButton{
    display: none;
}

.dzu-inputLabelWithFiles{
    display: none !important;
}



.no-orders{
    color: rgba(205, 198, 195, 1);
    font-family: Open Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
}

.no-products{
    color: rgba(205, 198, 195, 1);
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
}