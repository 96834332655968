.BSButton{
    width: 20%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: 75px;
    left: 5%;
    color: #fff;
}

.cardContainer{
    border: 1px solid #CDC6C3 !important;
    border-radius: 6px;
    margin: 6px;
    padding: 16px;
    width: 290px;
    height: 350px !important;
}

.gotoproducts{
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}

.header{
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;

}

.DPButton{
    width: 20%;
    background-color: #7BC0D3;
    border-radius: 30px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    position: absolute;
    top: 12%;
    height: 35px;
    left: 4%;
    color: #fff;
}


.displayNone{
    display: none;
}



@media screen and (max-height: 1024px) {
    .cardContainer{
        height: 300px;
        padding-left: 0px;
        padding-right: 0px;
    }

   
}