.tab_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 2%;
}

.tab_container h6 {
  text-transform: none;
}

.tab_container div {
  width: 33%;
  height: 50px;
}

.header{
  font-family: 'Open Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}

.alertContainerRed{
  margin: 8px;
  height: 32px;
  margin-top: 0px;
  margin-left: 34px;
  background-color: rgba(150, 50, 64, 0.16);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  font-weight: 600 !important;
  align-items: center;
  color: #963240;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}

.gotostock{
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

}

.alertContainerYellow{
  margin: 8px;
  height: 32px;
  margin-top: 0px;
  font-weight: 600 !important;
  margin-left: 34px;
  background-color: #EEE0CA;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #E29525;
}


.alertContainerGreen{
  margin: 8px;
  margin-left: 34px;
  height: 32px;
  margin-top: 0px;
  background-color: rgba(20, 124, 80, 0.16);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  font-weight: 600 !important;
  align-items: center;
  color: #147C50;
}


.AcknowledgedActive {
  background-color: rgba(123, 192, 211, 1);
  color: white;
}

.Acknowledged {
  border-bottom: 10px solid rgba(123, 192, 211, 1);
  color: black;
}

.OutofStock {
  border-bottom: 10px solid #963240;
  color: black;  
 
}

.OutofStockActive {
  background-color: #963240;
  color: white;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}

.OutofStockActive h6{
  font-weight: 600 !important;
  margin-top: 6px;
}

.contenttd{
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 19px;
  vertical-align: middle;
  letter-spacing: 0em;
}

.StockingOutActive h6{
  font-weight: 600 !important;
  margin-top: 6px;
}

.StockingOut {
  border-bottom: 10px solid #e29525;
}

.columntd{
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.StockingOutActive {
  background-color: #e29525;
  color: white;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}

.InStockActive h6{
  font-weight: 600 !important;
  margin-top: 6px;
}


.InStock {
  border-bottom: 10px solid rgba(20, 124, 80, 1);
}

.InStockActive {
  background-color: rgba(20, 124, 80, 1);
  color: white;
  font-weight: 900;
}
.StockStatusButton {
  background-color: #e5e5e5 !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
  text-align: center;
  background: #ebebec;
  border-radius: 30px !important;
  color: #808180 !important;
  border-color: #808180 !important;
  height: 32px;

  /*
    color: #808180 !important;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
/* identical to box height, or 114%

    display: flex;
    align-items: center;
    text-align: center;

    color: #808180;
    */
}
.StockButton {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    border-radius: 30px;
    width: 100%;
    text-align: center;
}
.instockbutton {
  background-color: #147c50;
  color: #ffffff;
}
.nullstockbutton {
  background-color: #ebebec;
  color: #808180;
  border: none;
}
.StockingOutbutton{
    background-color: #E29525;
    color: white;
}
