.tab_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6{
    text-transform: none;
}

.tab_container div {
    width: 20%;
    height: 50px;
}

.fontSize12{
    font-size: 12px;
}

.editProduct{
    text-decoration: underline;
    color: #1B74B9;
}

.tableContenttd{
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 10px;
    vertical-align: middle;
    padding-bottom: 10px !important;
}

.tableColumntd{
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.editProductContainer{
    transform: translate(10%,150%);
}

.editInput{
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
    border: none !important;
    border-bottom: 1px solid rgba(0,0,0,0.3) !important;
    text-align: center;
}


.filterButton{
    background-color: black;
    border-radius: 20px;
    color: white;
    width: 100%;
    font-size: 12px;
    margin-right: 10px;
    height: 35px !important;
    border: none;
}

.filterButton1{
    background-color: black;
    text-align: center;
    border-radius: 20px;
    color: white;
    align-items: center;
    width: 180px;
    height: 45px !important;
    border: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding:0;
  
}


.filterButton2{
    background-color: black;
    text-align: center;
    border-radius: 20px;
    color: white;
    align-items: center;
    width: 160px;
    height: 40px !important;
    border: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding:0;
  
}

.editStockBtn{
    background-color: #147C50 !important;
    text-align: center;
    border-radius: 20px;
    color: white;
    align-items: center;
    width: 180px;
    height: 45px !important;
    border: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding:0;
  
}

.editIcon{
    position: absolute;
    top: 20%;
    right: 0;
}


.tabOrders{
    border-bottom: 1px solid #171717;
}
.tabOrdersActive{
    border-bottom: 4px solid #171717;
}



.table{
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 10px;
}

.table tr{
    text-align: center; 
    background-color: #CDC6C3; 
    border: solid 1px #CDC6C3;
    border-style: solid none;
    padding: 10px;
    background-color: #CDC6C3;
}
.table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
    border-color: transparent;
}

.table td:last-child{
    border-right-style: solid;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
    border-color: transparent;
}

.table_tbody{
    width: 100% !important;
    border-collapse: separate !important; 
    border-spacing: 0 10px !important;
}

.table_tbody tr{
    display: block;
    text-align: center; 
    width: 100% !important;
    /* border-bottom: 0.2px solid rgba(0,0,0, 0.2) !important; */
    /* border: solid 1px #CDC6C3 !important;
    border-style: solid !important;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important; */
}

.stockDrop .show.dropdown.nav-item > .dropdown-menu.show{
    transform: translateX(100%) !important;
}


.nullstockbutton {
    width: 100%;
    background-color: #EBEBEC;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #808180;
}

.stockingoutButton{
    width: 100%;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #fff;
}


.instockbutton {
    width: 100%;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
}
 
.APInput{
    margin-left: 7%;
    border: none;
    width: 170px;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
}
.APHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
}

.APBody{
    display: flex;
    justify-content: center;
    margin-top: -27px;
    height: 250px;
    flex-direction: column;
    padding: 12px;
}


.APBody1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 250px;
    flex-direction: column;
}

.APMButton{
    width: 150px;
    height: 50px;
    border: 1px solid #000;
    background-color: black;
    color: white;
    border-radius: 30px;
    padding-top: 0px !important;
    margin-top: 5%;
}

.APMButton:hover{
    border: 1px solid rgba(244, 162, 147, 1);
}
.APLabelStatic{
    color: black;
    margin-left: 7%;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Open Sans';
}

.APLabel{
    color: rgba(205, 198, 195, 1);
    font-size: 12px;
    font-family: 'Open Sans';
}

.Ap{

    padding-top: 30px;
    padding-bottom: 30px;
}
.APDown{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 0px;
    margin-bottom: 10px;
}
.APlab{
    color: black;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}
.APborder{
    
    border: none;
    border-bottom: 1px solid black;
    width: 50%;

}
.APborder1{
    border: none;
    border-bottom: '2px solid black';
    
}
.filterButton1{
    background-color: black;
    text-align: center;
    border-radius: 20px;
    color: white !important;
    width: 220px;
    height: 45px !important;
    border: none;
}
.filterButton1 a{
    color: white !important;
}