.h1{
    color: rgb(56, 53, 53);
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    padding: 20px;
    padding-bottom: 30px;
}
.starimage{
    width: 40%;
    padding: 20px;
    padding-left: 15px;
}
.cardtext{
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    /* padding-bottom: 40px; */
    padding-left: 0;
    padding-top: 0px;
    padding-right: 0;
}
.cardfull{
    width: 312px;
    height: 179px;
    background-color:white;
    border:1px solid #CDC6C3; 
    border-radius: 10px;
}

@media (min-width:1900px) and (max-width:1950px){
    .cardfull{
        width: 275px;
    }
}

.title{
    font-size: 18px !important;
}