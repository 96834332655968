    .tab_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6 {
    text-transform: none;
}

.tab_container div {
    width: 25%;
    height: 50px;
}

.header{
    text-align: start;
    margin-right: 26px;
    font-size: 32px;
}

.table td{
    font-weight: 500;
    font-size: 0.8rem;
}

.alertContainerYellow{
    margin: 8px;
    background-color: #EEE0CA;
    border-radius: 20px;
    display: flex;
    height: 32px;
    justify-content: center;
    font-family: 'Open Sans';
    font-weight: 600 !important;
    font-size: 12px;
    align-items: center;
    color: #E29525;  
}

.columntable{
    height: 40px !important;
    margin-bottom: 24px !important;
}




.columntd{
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
}

.gotoOrders{
    font-size: 14px !important;
}

.table{
    width: 100%; 
    border-spacing: 15px;
}

.tableContenttd{
    text-align: center;
    padding-top: 24px !important;
    padding-bottom: 27px;
}

.table tr{
    margin-top: 10% !important;
}

.AcknowledgedActive {
    background-color: rgba(123, 192, 211, 1);
    color: white;
}

.Acknowledged {
    border-bottom: 10px solid rgba(123, 192, 211, 1);
    color: black;
}

.Accepted {
    border-bottom: 10px solid rgba(226, 149, 37, 1);
    color: black;
}


.AcceptedActive {
    background-color: rgba(226, 149, 37, 1);
    color: white;
}

.Shipped {
    border-bottom: 10px solid rgba(216, 157, 164, 1);
}

.ShippedActive {
    background-color: rgba(216, 157, 164, 1);
    color: white;
}

.Delivered {
    border-bottom: 10px solid rgba(20, 124, 80, 1);
}

.DeliveredActive {
    background-color: rgba(20, 124, 80, 1);
    color: white;
}

.declinebutton {
    width: 176px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 40px;
    border: 1px solid #171717;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    height: 48px !important;
    line-height: 16px;
    /* identical to box height, or 114% */
    align-items: center;
}

.acceptbutton {
    width: 176px !important;
    background-color: black;
    box-sizing: border-box;
    border-radius: 40px;
    border: 1px solid #171717;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    height: 48px !important;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    align-items: center;
    color: white
}

.noorder{
    opacity: 0.3;
}

.noorder h2{
    font-weight: 700;
    font-size: 40px;
    color:  #CDC6C3!important;
}


.noorder h4{
    font-weight: 700;
    font-size: 18px;
    color: rgba(205, 198, 195, 1) !important;
}

.instockbutton {
    width: 100%;
    background-color: #147C50;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    height: 40px !important;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #FFFFFF;
}
.nullstockbutton {
    width: 80%;
    background-color: #EBEBEC;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    height: 30px !important;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    /* identical to box height, or 114% */

    text-align: center;

    /* White New */

    color: #808180;
}
.head{
    padding-left: 50%;
}