.tab_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6{
    text-transform: none;
}

.tab_container div {
    width: 25%;
    height: 50px;
    
    
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey; 
  }


.AcknowledgedActive{
    background-color: rgba(123, 192, 211, 1);
    color: white;
}

.header{
    font-family: Open Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
}


.Acknowledged{
    border-bottom: 10px solid rgba(123, 192, 211, 1);
    color: black;
}

.Accepted{
    border-bottom: 10px solid rgba(226, 149, 37, 1);
    color: black;
}


.AcceptedActive{
    background-color: rgba(226, 149, 37, 1);
    color: white;
}

.Shipped{
    border-bottom: 10px solid rgba(216, 157, 164, 1);
}

.ShippedActive{
    background-color: rgba(216, 157, 164, 1);
    color: white;
}

.Delivered{
    border-bottom: 10px solid rgba(20, 124, 80, 1);
}

.DeliveredActive{
    background-color: rgba(20, 124, 80, 1);
    color: white;
}
.Button{
    width: 80%;
    background-color: #7BC0D3;
    border-radius: 30px;
    border: none;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    
}
.tab_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
}

.tab_container h6 {
    text-transform: none;
}

.tab_container div {
    width: 25%;
    height: 50px;
}
.active{
    border-bottom: 5px solid #171717;
}
.notactive{
    border-bottom: 1px solid #171717;
}
.paymentbutton{
    width: 80%;
    border-radius: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid;
    text-align: center;
    color: #FFFFFF;
}
.paymentdue{
    background-color: #963240;
}
.notpaymentdue{
    background-color: #147C50;
}
.tablecol{
    text-align: center;
    width: 10%;
    padding: 10px;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    vertical-align: middle;
}

.tablecol1{
    text-align: center;
    width: 15%;
    padding: 10px;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    vertical-align: middle;
}

.hyperlink{
    color: rgba(27, 116, 185, 1);
    text-decoration: underline;
}

.tablerow{
    text-align: center;
    height: 80px;
    overflow: scroll;
}
.notdeposited{
    background-color: #E29525;
}

.marginBottom{
    font-size: 12px !important;
}
.filterButton1{
    background-color: black;
    text-align: center;
    border-radius: 20px;
    color: white;
    align-items: center;
    width: 180px;
    height: 45px;
    border: none;
    display: flex;
    justify-content: center; 
    margin: 0px 9px;
    padding:0;
  
}
.filterButton1 img{
    padding-top: 10px;
}
.APInput{
    margin-left: 7%;
    border: none;
    width: 170px;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    text-align: center;
}

.APSelect{
    border: none;
    width: 100%;
    border-bottom: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center !important;
    text-indent: 1px;
    text-align-last:center !important;
    text-overflow: '';
}

.APSelect option{
    text-align: center !important;
}
.APSelect:focus{
    outline: none !important;
    box-shadow: none !important;
}

.APSelect::-ms-expand {
    display: none !important;
}

.APLabelStatic{
    color: black;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    font-family: 'Open Sans';
    padding-left: 24px;
}

.APTextArea{
    border: 1px solid rgba(205, 198, 195, 1);
    font-size: 12px;
    border-radius: 10px;
    height: 80px;
    margin-top: 2%;
}

.APBody2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 238px;
    flex-direction: column;
  margin-bottom: -9px;
    width: 252px;
}

.APHeader2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
   
    
}
.APHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6%;
}
.APBody{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 116px;
    flex-direction: column;
}

.APMButton1{
    width: 176px !important;
    height: 40px !important;
    border: 1px solid #000;
    background-color: black;
    color: white;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700 !important;
    margin-bottom: 2%;
    font-size: 12px !important;
    margin-top: 7%;
}

.APMButton1:hover{
    color: #FFFFFF !important;
}

.APborder {
    text-align-last:center;
    border-bottom-color: #CDC6C3 !important;
    font-size: 14px !important;
}

.AP1{
    margin-top: 22px;
    margin-bottom: 24px;
}
.APMButton:hover{
    border: 2px solid black;
}
.APborder{
    max-width:59%;
    border: none;
    border-bottom: 1px solid black;
}
.APlab{
    width: 284px !important;
}
.APborder1{
    border: none;
    border-bottom: '2px solid black';
    
}
.APDown{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 5px;
}
.AP2{
        margin-top: 22px;
        margin-bottom: 24px;
}
.ApLarge{
   
        width:auto;
    
}
.APHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
}

.APBody{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    flex-direction: column;
}


.APBody1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 250px;
    flex-direction: column;
}

.APMButton{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    width: 150px;
    height: 50px;
    border: 1px solid #000;
    background-color: white;
    color: black;
    border-radius: 30px;
    padding: 16px !important;
    margin-top: 5%;
}

.APMButton:hover{
    border: 2px solid black;
}
