.tab_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2%;
    cursor: pointer;
}

.tab_container h6{
    text-transform: none;
    cursor: pointer;
}

.tab_container div {
    width: 25%;
    height: 50px;
}

.header{
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
}

.AcknowledgedActive{
    background-color: rgba(123, 192, 211, 1);
    color: white;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 900 !important;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 6px;

}

.AcknowledgedActive h6{
    background-color: rgba(123, 192, 211, 1);
    color: white;
    font-weight: 700 !important;
}

.Acknowledged{
    border-bottom: 10px solid rgba(123, 192, 211, 1);
    color: black;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 10px;

}

.AcceptedActive h6{
    font-weight: 700 !important;
}

.ShippedActive h6{
    font-weight: 700 !important;
}

.DeliveredActive h6{
    font-weight: 700 !important;
}
.AcknowledgedActive h6{
    font-weight: 700 !important;
}

.alertContainerRed{
    margin: 8px;
    margin-left: 20px;
    margin-top: 0px;
    background-color: rgba(123, 192, 211, 0.16);
    border-radius: 20px;
    display: flex;
    height: 32px !important;
    justify-content: center;
    font-weight: 600 !important;
    align-items: center;
    color: #7BC0D3;
  }
  
  .alertContainerYellow{
    margin: 8px;
    margin-left: 34px;
    background-color: #EEE0CA;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    font-weight: 600 !important;
    align-items: center;
    color: #E29525;
  }

  
  .alertContainerPink{
    margin: 8px;
    margin-left: 34px;
    background-color:rgba(216, 157, 164, 0.16);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    font-weight: 600 !important;
    align-items: center;
    color: rgba(216, 157, 164, 1);
  }
  
  .alertContainerGreen{
    margin: 8px;
    margin-left: 34px;
    background-color: rgba(20, 124, 80, 0.16);
    font-weight: 600 !important;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #147C50;
  }

.acknowledgeButton{
    width: 140px !important;
    height: 35px !important;
    margin-top: 5px;
    background-color: #7BC0D3;
    border-radius: 30px;
    border: none;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.Accepted{
    border-bottom: 10px solid rgba(226, 149, 37, 1);
    color: black;
    color: black;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 10px;
}


.AcceptedActive{
    background-color: rgba(226, 149, 37, 1);
    color: white;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 6px;
}
.acceptedButton{
    width: 130px;
    background-color: white;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: #E29525;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #E29525;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.Shipped{
    border-bottom: 10px solid rgba(216, 157, 164, 1);
    color: black;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 10px;
}

.ShippedActive{
    background-color: rgba(216, 157, 164, 1);
    color: white;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 6px;
}
.shippedButton{
    width: 130px;
    background-color: white;
    border-radius: 30px;
    border: none;
    height: 35px !important;
    color: #147C50;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #147C50;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.contenttabletd{
    font-family: 'Open Sans';
    font-size: 14px  !important;
    font-style: normal  !important;
    font-weight: 400 !important;
    line-height: 18px  !important;
    letter-spacing: 0em  !important;
    text-align: center;
}

.columntd{
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.Delivered{
    border-bottom: 10px solid rgba(20, 124, 80, 1);
    color: black;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 10px;
}

.DeliveredActive{
    background-color: rgba(20, 124, 80, 1);
    color: white;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 6px;
}
.deliveredButton{
    width: 130px;
    background-color: #147C50;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #147C50;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}
.Button{
    width: 80%;
    background-color: #7BC0D3;
    border-radius: 30px;
    border: none;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    
}

.acknowledgeButton{
    width: 140px !important;
    height: 35px !important;
    margin-top: 5px;
    background-color: #7BC0D3;
    border-radius: 30px;
    border: none;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.shippedButton{
    width: 130px;
    background-color: white;
    border-radius: 30px;
    border: none;
    height: 35px !important;
    color: #147C50;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #147C50;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.acceptedButton{
    width: 130px;
    background-color: white;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: #E29525;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #E29525;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.deliveredButton{
    width: 130px;
    background-color: #147C50;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #147C50;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}

.returnedButton{
    width: 130px;
    background-color: white;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: #963240;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #963240;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}
.cancelledButton{
    width: 130px;
    background-color: #963240;
    margin-top: 5px;
    border-radius: 30px;
    border: none;
    height: 32px !important;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #963240;
    line-height: 16px;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;  
}