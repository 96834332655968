.instock-button{
    width: 120px;
    height: 32px;
    background-color: #147C50;
    border-radius: 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: none;
    font-weight: bold;
}

.stockingout-button{
    width: 120px;
    height: 32px;
    background-color: rgba(226, 149, 37, 1);
    border-radius: 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: none;
    font-weight: bold;
}


.outstock-button{
    width: 120px;
    height: 32px;
    background-color: #EBEBEC;
    border-radius: 15px;
    color: #808180;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: none;
    font-weight: bold;
}

.acknowledged-button{
    height: 32px;
    width: 120px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(123, 192, 211, 1);
}

.accepted-button{
    height: 32px;
    width: 120px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E29525;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #E29525;
    background-color: #FFFFFF;
}

.shipped-button{
    height: 32px;
    width: 120px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(20, 124, 80, 1);
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(20, 124, 80, 1);
    background-color: #FFFFFF;
}


.delivered-button{
    height: 32px;
    width: 120px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    background-color:rgba(20, 124, 80, 1);
}

.cancelled-button{
    height: 32px;
    width: 120px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    background-color:rgba(150, 50, 64, 1);
}

.returned-button{
    height: 32px;
    width: 120px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(150, 50, 64, 1);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(150, 50, 64, 1);
    background-color:#FFFFFF;
}

.product-list-row{
    padding-left: 27px;
    padding-right: 10px;
}

.img-container{
    max-width:313px;
    max-height: 204px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.p-divider{
    border-bottom: 1px solid #CDC6C3;
}

.p-header{
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.p-content{
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    margin-top: 10px;
    text-align: center;
}

.img-container img{
    width: 100%;
    height: 100%;
}

.filter-button{
    height: 48px;
    width: 176px;
    border-radius: 40px;
    background-color: #000;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;    
    color: white;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;

}

.filter-icon{
    height: 25px;
    width: 25px;
    margin-top: 7px;
    margin-right: 7px;
}

.download-report{
    height: 48px;
    width: 176px;
    border-radius: 40px;
    background-color: #000;
    color: #FFFFFF;
    border: none;
    margin-left: 13px;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
}