.maincontainer{
    margin-top: 75px;
}

.alertContainerYellow{
    margin: 8px;
    background-color: #EEE0CA;
    border-radius: 20px;
    display: flex;
    height: 32px;
    justify-content: center;
    font-family: 'Open Sans';
    font-weight: 600 !important;
    font-size: 12px;
    align-items: center;
    color: #E29525;  
}




.title{
    font-size: 14px;
    font-weight: 400;
    color: #171717;
}

.price{
    font-size: 24px !important;
    color: rgba(23, 23, 23, 1);
    font-weight: 700;
}

.count{
    font-weight: 700;
    font-size: 40px;
}

.gotofinances{
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

  .dropdown {
    width: 100px;
    user-select: none;
    margin: 100px auto;
    position: relative;
    text-align: end;
  }
  .dropdown .dropdown-btn {
    padding: 15px 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
  }
 

  .dropdown .dropdown-content {
    
    position: absolute;
    top: 110%;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    font-weight: 500;
    color: #333;
    width: 95%;
  }
  .dropdown .dropdown-content .dropdown-item {
    cursor: pointer;
    transition: all 0.2s;
    border:1px solid grey;
    
  }
  .dropdown .dropdown-content .dropdown-item:hover {
    background: #f4f4f4;
  }
  .dropdown-item{
      text-align: center !important;
  }
  